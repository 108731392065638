export const Accordion = () => import('../../components/common/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/common/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const Alerts = () => import('../../components/common/Alerts.vue' /* webpackChunkName: "components/alerts" */).then(c => wrapFunctional(c.default || c))
export const Badge = () => import('../../components/common/Badge.vue' /* webpackChunkName: "components/badge" */).then(c => wrapFunctional(c.default || c))
export const Calendar = () => import('../../components/common/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const CheckBoxButton = () => import('../../components/common/CheckBoxButton.vue' /* webpackChunkName: "components/check-box-button" */).then(c => wrapFunctional(c.default || c))
export const CircleProgressBar = () => import('../../components/common/CircleProgressBar.vue' /* webpackChunkName: "components/circle-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const FilesBrowser = () => import('../../components/common/FilesBrowser.vue' /* webpackChunkName: "components/files-browser" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/common/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const InputTag = () => import('../../components/common/InputTag.vue' /* webpackChunkName: "components/input-tag" */).then(c => wrapFunctional(c.default || c))
export const LangPicker = () => import('../../components/common/LangPicker.vue' /* webpackChunkName: "components/lang-picker" */).then(c => wrapFunctional(c.default || c))
export const LoadingButton = () => import('../../components/common/LoadingButton.vue' /* webpackChunkName: "components/loading-button" */).then(c => wrapFunctional(c.default || c))
export const LoadingIcon = () => import('../../components/common/LoadingIcon.vue' /* webpackChunkName: "components/loading-icon" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/common/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/common/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/common/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PaginationLinks = () => import('../../components/common/PaginationLinks.vue' /* webpackChunkName: "components/pagination-links" */).then(c => wrapFunctional(c.default || c))
export const QModal = () => import('../../components/common/QModal.vue' /* webpackChunkName: "components/q-modal" */).then(c => wrapFunctional(c.default || c))
export const QModal2 = () => import('../../components/common/QModal2.vue' /* webpackChunkName: "components/q-modal2" */).then(c => wrapFunctional(c.default || c))
export const SearchPagination = () => import('../../components/common/SearchPagination.vue' /* webpackChunkName: "components/search-pagination" */).then(c => wrapFunctional(c.default || c))
export const SelectItemsDropdown = () => import('../../components/common/SelectItemsDropdown.vue' /* webpackChunkName: "components/select-items-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SimpleDatePicker = () => import('../../components/common/SimpleDatePicker.vue' /* webpackChunkName: "components/simple-date-picker" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/common/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const SortDropDown = () => import('../../components/common/SortDropDown.vue' /* webpackChunkName: "components/sort-drop-down" */).then(c => wrapFunctional(c.default || c))
export const TipTapEditor = () => import('../../components/common/TipTapEditor.vue' /* webpackChunkName: "components/tip-tap-editor" */).then(c => wrapFunctional(c.default || c))
export const Tippy = () => import('../../components/common/Tippy.vue' /* webpackChunkName: "components/tippy" */).then(c => wrapFunctional(c.default || c))
export const ToggleButtons = () => import('../../components/common/ToggleButtons.vue' /* webpackChunkName: "components/toggle-buttons" */).then(c => wrapFunctional(c.default || c))
export const DateCalendar = () => import('../../components/common/Date/Calendar.vue' /* webpackChunkName: "components/date-calendar" */).then(c => wrapFunctional(c.default || c))
export const DateHeadleesCalendar = () => import('../../components/common/Date/HeadleesCalendar.vue' /* webpackChunkName: "components/date-headlees-calendar" */).then(c => wrapFunctional(c.default || c))
export const FeatureLimitExceeded = () => import('../../components/common/FeatureLimits/FeatureLimitExceeded.vue' /* webpackChunkName: "components/feature-limit-exceeded" */).then(c => wrapFunctional(c.default || c))
export const FeatureLimitsSearchFeatureLimitExceeded = () => import('../../components/common/FeatureLimits/SearchFeatureLimitExceeded.vue' /* webpackChunkName: "components/feature-limits-search-feature-limit-exceeded" */).then(c => wrapFunctional(c.default || c))
export const InputsSelectInput = () => import('../../components/common/Inputs/SelectInput.vue' /* webpackChunkName: "components/inputs-select-input" */).then(c => wrapFunctional(c.default || c))
export const InputsSelectSearchInput = () => import('../../components/common/Inputs/SelectSearchInput.vue' /* webpackChunkName: "components/inputs-select-search-input" */).then(c => wrapFunctional(c.default || c))
export const InputsTextInput = () => import('../../components/common/Inputs/TextInput.vue' /* webpackChunkName: "components/inputs-text-input" */).then(c => wrapFunctional(c.default || c))
export const InputsTextInputMasked = () => import('../../components/common/Inputs/TextInputMasked.vue' /* webpackChunkName: "components/inputs-text-input-masked" */).then(c => wrapFunctional(c.default || c))
export const InputsTextareaInput = () => import('../../components/common/Inputs/TextareaInput.vue' /* webpackChunkName: "components/inputs-textarea-input" */).then(c => wrapFunctional(c.default || c))
export const ModalsModal = () => import('../../components/common/Modals/Modal.vue' /* webpackChunkName: "components/modals-modal" */).then(c => wrapFunctional(c.default || c))
export const OTPOneTimePassword = () => import('../../components/common/OTP/OneTimePassword.vue' /* webpackChunkName: "components/o-t-p-one-time-password" */).then(c => wrapFunctional(c.default || c))
export const Otp = () => import('../../components/common/OTP/Otp.vue' /* webpackChunkName: "components/otp" */).then(c => wrapFunctional(c.default || c))
export const OtpErrorMessage = () => import('../../components/common/OTP/OtpErrorMessage.vue' /* webpackChunkName: "components/otp-error-message" */).then(c => wrapFunctional(c.default || c))
export const OtpGroup = () => import('../../components/common/OTP/OtpGroup.vue' /* webpackChunkName: "components/otp-group" */).then(c => wrapFunctional(c.default || c))
export const OtpGroupInput = () => import('../../components/common/OTP/OtpGroupInput.vue' /* webpackChunkName: "components/otp-group-input" */).then(c => wrapFunctional(c.default || c))
export const TwoFactorAuthSms2FA = () => import('../../components/common/TwoFactorAuth/Sms2FA.vue' /* webpackChunkName: "components/two-factor-auth-sms2-f-a" */).then(c => wrapFunctional(c.default || c))
export const DateInstanceCalendarDate = () => import('../../components/common/Date/Instance/CalendarDate.js' /* webpackChunkName: "components/date-instance-calendar-date" */).then(c => wrapFunctional(c.default || c))
export const DateInstanceHijriCalendarDate = () => import('../../components/common/Date/Instance/HijriCalendarDate.js' /* webpackChunkName: "components/date-instance-hijri-calendar-date" */).then(c => wrapFunctional(c.default || c))
export const DateFormat = () => import('../../components/common/Date/Utils/dateFormat.js' /* webpackChunkName: "components/date-format" */).then(c => wrapFunctional(c.default || c))
export const DateUtilsHelpers = () => import('../../components/common/Date/Utils/helpers.js' /* webpackChunkName: "components/date-utils-helpers" */).then(c => wrapFunctional(c.default || c))
export const EditorMentionList = () => import('../../components/common/Editor/Mention/MentionList.vue' /* webpackChunkName: "components/editor-mention-list" */).then(c => wrapFunctional(c.default || c))
export const EditorMentionSuggestion = () => import('../../components/common/Editor/Mention/suggestion.js' /* webpackChunkName: "components/editor-mention-suggestion" */).then(c => wrapFunctional(c.default || c))
export const DateLocaleGregorianAr = () => import('../../components/common/Date/Locale/gregorian/ar.js' /* webpackChunkName: "components/date-locale-gregorian-ar" */).then(c => wrapFunctional(c.default || c))
export const DateLocaleGregorianEn = () => import('../../components/common/Date/Locale/gregorian/en.js' /* webpackChunkName: "components/date-locale-gregorian-en" */).then(c => wrapFunctional(c.default || c))
export const DateLocaleHijriAr = () => import('../../components/common/Date/Locale/hijri/ar.js' /* webpackChunkName: "components/date-locale-hijri-ar" */).then(c => wrapFunctional(c.default || c))
export const HxNFilter = () => import('../../components/hxn/HxNFilter.vue' /* webpackChunkName: "components/hx-n-filter" */).then(c => wrapFunctional(c.default || c))
export const HxNItem = () => import('../../components/hxn/HxNItem.vue' /* webpackChunkName: "components/hx-n-item" */).then(c => wrapFunctional(c.default || c))
export const CheckBoxInput = () => import('../../components/inputs/CheckBoxInput.vue' /* webpackChunkName: "components/check-box-input" */).then(c => wrapFunctional(c.default || c))
export const DateRangeInput = () => import('../../components/inputs/DateRangeInput.vue' /* webpackChunkName: "components/date-range-input" */).then(c => wrapFunctional(c.default || c))
export const FileUploadInput = () => import('../../components/inputs/FileUploadInput.vue' /* webpackChunkName: "components/file-upload-input" */).then(c => wrapFunctional(c.default || c))
export const LogoInput = () => import('../../components/inputs/LogoInput.vue' /* webpackChunkName: "components/logo-input" */).then(c => wrapFunctional(c.default || c))
export const RadioInput = () => import('../../components/inputs/RadioInput.vue' /* webpackChunkName: "components/radio-input" */).then(c => wrapFunctional(c.default || c))
export const RecaptchaInput = () => import('../../components/inputs/RecaptchaInput.vue' /* webpackChunkName: "components/recaptcha-input" */).then(c => wrapFunctional(c.default || c))
export const SelectInput = () => import('../../components/inputs/SelectInput.vue' /* webpackChunkName: "components/select-input" */).then(c => wrapFunctional(c.default || c))
export const TextBtnInput = () => import('../../components/inputs/TextBtnInput.vue' /* webpackChunkName: "components/text-btn-input" */).then(c => wrapFunctional(c.default || c))
export const TextInput = () => import('../../components/inputs/TextInput.vue' /* webpackChunkName: "components/text-input" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../components/main/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const FooterBar = () => import('../../components/main/FooterBar.vue' /* webpackChunkName: "components/footer-bar" */).then(c => wrapFunctional(c.default || c))
export const HeaderBar = () => import('../../components/main/HeaderBar.vue' /* webpackChunkName: "components/header-bar" */).then(c => wrapFunctional(c.default || c))
export const HeaderBarNew = () => import('../../components/main/HeaderBarNew.vue' /* webpackChunkName: "components/header-bar-new" */).then(c => wrapFunctional(c.default || c))
export const HeaderBarYLLKSA = () => import('../../components/main/HeaderBarYLLKSA.vue' /* webpackChunkName: "components/header-bar-y-l-l-k-s-a" */).then(c => wrapFunctional(c.default || c))
export const NationalHeaderBar = () => import('../../components/main/NationalHeaderBar.vue' /* webpackChunkName: "components/national-header-bar" */).then(c => wrapFunctional(c.default || c))
export const CategoriesContractTemplatesSlider = () => import('../../components/Search/CategoriesContractTemplatesSlider.vue' /* webpackChunkName: "components/categories-contract-templates-slider" */).then(c => wrapFunctional(c.default || c))
export const CategoriesFilesSlider = () => import('../../components/Search/CategoriesFilesSlider.vue' /* webpackChunkName: "components/categories-files-slider" */).then(c => wrapFunctional(c.default || c))
export const DateFilterCalendar = () => import('../../components/Search/DateFilterCalendar.vue' /* webpackChunkName: "components/date-filter-calendar" */).then(c => wrapFunctional(c.default || c))
export const InputDropdown = () => import('../../components/Search/InputDropdown.vue' /* webpackChunkName: "components/input-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MainTagsFilter = () => import('../../components/Search/MainTagsFilter.vue' /* webpackChunkName: "components/main-tags-filter" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/Search/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchHistoryItem = () => import('../../components/Search/SearchHistoryItem.vue' /* webpackChunkName: "components/search-history-item" */).then(c => wrapFunctional(c.default || c))
export const SearchHistoryList = () => import('../../components/Search/SearchHistoryList.vue' /* webpackChunkName: "components/search-history-list" */).then(c => wrapFunctional(c.default || c))
export const SearchResult = () => import('../../components/Search/SearchResult.vue' /* webpackChunkName: "components/search-result" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../../components/Search/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const SearchTagMultiSelect = () => import('../../components/Search/SearchTagMultiSelect.vue' /* webpackChunkName: "components/search-tag-multi-select" */).then(c => wrapFunctional(c.default || c))
export const SuggestedCategory = () => import('../../components/Search/SuggestedCategory.vue' /* webpackChunkName: "components/suggested-category" */).then(c => wrapFunctional(c.default || c))
export const SuggestedFile = () => import('../../components/Search/SuggestedFile.vue' /* webpackChunkName: "components/suggested-file" */).then(c => wrapFunctional(c.default || c))
export const Suggestion = () => import('../../components/Search/Suggestion.vue' /* webpackChunkName: "components/suggestion" */).then(c => wrapFunctional(c.default || c))
export const SuggestionWrapper = () => import('../../components/Search/SuggestionWrapper.vue' /* webpackChunkName: "components/suggestion-wrapper" */).then(c => wrapFunctional(c.default || c))
export const SugguestedCategories = () => import('../../components/Search/SugguestedCategories.vue' /* webpackChunkName: "components/sugguested-categories" */).then(c => wrapFunctional(c.default || c))
export const TableSearchResults = () => import('../../components/Search/TableSearchResults.vue' /* webpackChunkName: "components/table-search-results" */).then(c => wrapFunctional(c.default || c))
export const FilteringDateSearchFilter = () => import('../../components/Search/Filtering/DateSearchFilter.vue' /* webpackChunkName: "components/filtering-date-search-filter" */).then(c => wrapFunctional(c.default || c))
export const FilteringSearchFilter = () => import('../../components/Search/Filtering/SearchFilter.vue' /* webpackChunkName: "components/filtering-search-filter" */).then(c => wrapFunctional(c.default || c))
export const FilteringSearchFilters = () => import('../../components/Search/Filtering/SearchFilters.vue' /* webpackChunkName: "components/filtering-search-filters" */).then(c => wrapFunctional(c.default || c))
export const FilteringTextSearchFilter = () => import('../../components/Search/Filtering/TextSearchFilter.vue' /* webpackChunkName: "components/filtering-text-search-filter" */).then(c => wrapFunctional(c.default || c))
export const SuggestionsContractTemplateResult = () => import('../../components/Search/Suggestions/ContractTemplateResult.vue' /* webpackChunkName: "components/suggestions-contract-template-result" */).then(c => wrapFunctional(c.default || c))
export const SuggestionsSuggestionSlider = () => import('../../components/Search/Suggestions/SuggestionSlider.vue' /* webpackChunkName: "components/suggestions-suggestion-slider" */).then(c => wrapFunctional(c.default || c))
export const Suggestions = () => import('../../components/Search/Suggestions/Suggestions.vue' /* webpackChunkName: "components/suggestions" */).then(c => wrapFunctional(c.default || c))
export const SuggestionsTemplates = () => import('../../components/Search/Suggestions/SuggestionsTemplates.vue' /* webpackChunkName: "components/suggestions-templates" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
