
export default {
    props: {
        color: {
            type: String,
            default: 'success',
            validator: val => ['success', 'error', 'green', 'blue', 'yellow', 'red', 'orange', 'gray', 'purple'].includes(val)
        },
        theme: {
            type: String,
            default: 'light',
            validator: val => ['light', 'outline'].includes(val)
        },
        dot: {
            type: Boolean,
            default: false
        }
    }
}
