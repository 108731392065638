
import HeaderBar from '@/components/Layout/HeaderBar.vue'
import { tippy } from '~/Utils/directives'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import PublicHeaderBar from '~/components/Layout/PublicHeaderBar.vue'

export default {
    components: { PublicHeaderBar },
    directives: { tippy },
    data () {
        return {
            subscription: null,
        }
    },
    mounted () {
        this.loadSubscription()
    },
    methods: {
        async loadSubscription () {
            if (!this.$auth.loggedIn || this.$auth?.user?.is_admin) {
                return
            }
            const form = new this.$vForm()
            const { data } = await form.get('v1/CurrentSubscription')
            if (!data) {
                this.$router.push('/Packages')
            }
            this.subscription = data.subscription
        }
    }
}
