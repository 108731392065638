
import Tippy from '@/components/common/Tippy.vue'
import Badge from '@/components/UntitledUI/Badge.vue'
import Icon from '~/components/UntitledUI/Icon.vue'

export default {
    name: 'AccountSwitcher',
    components: { Tippy, Badge, Icon },
    props: {
        expand: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            loading: false
        }
    },
    computed: {
        currentTenant () {
            return this.$auth.user.tenants.find(ten => ten.id === this.$auth.user.tenant_id)
        }
    },
    methods: {
        productLabel (tenant) {
            return {
                legal: 'البحث القانوني',
                hr: 'الموارد البشرية'
            }[tenant.product]
        },
        tenantTeamType (tenant) {
            if (tenant.name) {
                return tenant.name
            }

            if (!tenant.team) {
                return 'حساب شخصي'
            }

            if (!tenant.name && !tenant.owner) {
                return 'عضو فريق'
            }

            return 'حساب فريق'
        },
        async switchAccount (tenant) {
            if (tenant.id === this.currentTenant.id || this.loading) {
                return
            }
            this.loading = true
            await this.$axios.$patch('/v1/switch-tenant/' + tenant.id)
                .then(() => {
                    window.location.reload()
                })
                .catch((e) => {
                    this.$toast.error('حدث خطأ ما')
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
