import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _696b1816 = () => interopDefault(import('../pages/Changelogs/index.vue' /* webpackChunkName: "pages/Changelogs/index" */))
const _7f33809b = () => interopDefault(import('../pages/ContactUs.vue' /* webpackChunkName: "pages/ContactUs" */))
const _3189e414 = () => interopDefault(import('../pages/Content.vue' /* webpackChunkName: "pages/Content" */))
const _dd280c3e = () => interopDefault(import('../pages/Contracts/index.vue' /* webpackChunkName: "pages/Contracts/index" */))
const _67957dc2 = () => interopDefault(import('../pages/DataView.vue' /* webpackChunkName: "pages/DataView" */))
const _0e2d6abc = () => interopDefault(import('../pages/Expired.vue' /* webpackChunkName: "pages/Expired" */))
const _646b055c = () => interopDefault(import('../pages/ExtendTrial/index.vue' /* webpackChunkName: "pages/ExtendTrial/index" */))
const _149a8b71 = () => interopDefault(import('../pages/ForgotPassword.vue' /* webpackChunkName: "pages/ForgotPassword" */))
const _226501b0 = () => interopDefault(import('../pages/GettingStarted.vue' /* webpackChunkName: "pages/GettingStarted" */))
const _0b8c74cc = () => interopDefault(import('../pages/HelpCenter/index.vue' /* webpackChunkName: "pages/HelpCenter/index" */))
const _393a67c8 = () => interopDefault(import('../pages/HighlightsAndNotes.vue' /* webpackChunkName: "pages/HighlightsAndNotes" */))
const _6c258fae = () => interopDefault(import('../pages/Login/index.vue' /* webpackChunkName: "pages/Login/index" */))
const _6cad86d1 = () => interopDefault(import('../pages/LoginOld.vue' /* webpackChunkName: "pages/LoginOld" */))
const _382fe67c = () => interopDefault(import('../pages/NotFound.vue' /* webpackChunkName: "pages/NotFound" */))
const _0ea7bf25 = () => interopDefault(import('../pages/Notifications.vue' /* webpackChunkName: "pages/Notifications" */))
const _625d2a40 = () => interopDefault(import('../pages/Packages.vue' /* webpackChunkName: "pages/Packages" */))
const _a1e85fd2 = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "pages/PrivacyPolicy" */))
const _4d3778f0 = () => interopDefault(import('../pages/Referral.vue' /* webpackChunkName: "pages/Referral" */))
const _f930a20e = () => interopDefault(import('../pages/Register/index.vue' /* webpackChunkName: "pages/Register/index" */))
const _f56eb304 = () => interopDefault(import('../pages/Search/index.vue' /* webpackChunkName: "pages/Search/index" */))
const _1de7f314 = () => interopDefault(import('../pages/Settings.vue' /* webpackChunkName: "pages/Settings" */))
const _2c48b613 = () => interopDefault(import('../pages/Stream.vue' /* webpackChunkName: "pages/Stream" */))
const _14041276 = () => interopDefault(import('../pages/Subscriptions/index.vue' /* webpackChunkName: "pages/Subscriptions/index" */))
const _324bf80b = () => interopDefault(import('../pages/Table.vue' /* webpackChunkName: "pages/Table" */))
const _701d3e86 = () => interopDefault(import('../pages/Tenants.vue' /* webpackChunkName: "pages/Tenants" */))
const _552d446e = () => interopDefault(import('../pages/UnderMaintenance.vue' /* webpackChunkName: "pages/UnderMaintenance" */))
const _77e277a0 = () => interopDefault(import('../pages/UsagePolicy.vue' /* webpackChunkName: "pages/UsagePolicy" */))
const _3a057832 = () => interopDefault(import('../pages/Workers.vue' /* webpackChunkName: "pages/Workers" */))
const _539d17da = () => interopDefault(import('../pages/YLLKSA.vue' /* webpackChunkName: "pages/YLLKSA" */))
const _b7c4b0c8 = () => interopDefault(import('../pages/AI/Chat.vue' /* webpackChunkName: "pages/AI/Chat" */))
const _fe9a9746 = () => interopDefault(import('../pages/Forms/Contact.vue' /* webpackChunkName: "pages/Forms/Contact" */))
const _15bf8c9a = () => interopDefault(import('../pages/Forms/trial.vue' /* webpackChunkName: "pages/Forms/trial" */))
const _0993e874 = () => interopDefault(import('../pages/landings/enterprise/index.vue' /* webpackChunkName: "pages/landings/enterprise/index" */))
const _5cae3add = () => interopDefault(import('../pages/landings/hr/index.vue' /* webpackChunkName: "pages/landings/hr/index" */))
const _c8f11bb4 = () => interopDefault(import('../pages/landings/lawfirms/index.vue' /* webpackChunkName: "pages/landings/lawfirms/index" */))
const _729f8b12 = () => interopDefault(import('../pages/landings/lawyers/index.vue' /* webpackChunkName: "pages/landings/lawyers/index" */))
const _7d79e43c = () => interopDefault(import('../pages/landings/reports/index.vue' /* webpackChunkName: "pages/landings/reports/index" */))
const _5aa922f5 = () => interopDefault(import('../pages/Mobile/Download/index.vue' /* webpackChunkName: "pages/Mobile/Download/index" */))
const _2a8ebaf1 = () => interopDefault(import('../pages/OAuth/Authorize/index.vue' /* webpackChunkName: "pages/OAuth/Authorize/index" */))
const _595921c6 = () => interopDefault(import('../pages/Payments/Fail.vue' /* webpackChunkName: "pages/Payments/Fail" */))
const _1748e754 = () => interopDefault(import('../pages/Payments/Success.vue' /* webpackChunkName: "pages/Payments/Success" */))
const _a0030f78 = () => interopDefault(import('../pages/Register/OnBoarding.vue' /* webpackChunkName: "pages/Register/OnBoarding" */))
const _4ee985ca = () => interopDefault(import('../pages/Register/Success.vue' /* webpackChunkName: "pages/Register/Success" */))
const _8d557dc6 = () => interopDefault(import('../pages/Team/Members/index.vue' /* webpackChunkName: "pages/Team/Members/index" */))
const _038211b6 = () => interopDefault(import('../pages/landings/enterprise/go.vue' /* webpackChunkName: "pages/landings/enterprise/go" */))
const _4dc63968 = () => interopDefault(import('../pages/landings/enterprise/linkd.vue' /* webpackChunkName: "pages/landings/enterprise/linkd" */))
const _1a21cdd1 = () => interopDefault(import('../pages/landings/enterprise/tw.vue' /* webpackChunkName: "pages/landings/enterprise/tw" */))
const _135c0026 = () => interopDefault(import('../pages/landings/hr/go.vue' /* webpackChunkName: "pages/landings/hr/go" */))
const _2c3735b5 = () => interopDefault(import('../pages/landings/hr/linkd.vue' /* webpackChunkName: "pages/landings/hr/linkd" */))
const _0cf1bc08 = () => interopDefault(import('../pages/landings/hr/tw.vue' /* webpackChunkName: "pages/landings/hr/tw" */))
const _340f9578 = () => interopDefault(import('../pages/landings/lawfirms/go.vue' /* webpackChunkName: "pages/landings/lawfirms/go" */))
const _6b106cfe = () => interopDefault(import('../pages/landings/lawfirms/linkd.vue' /* webpackChunkName: "pages/landings/lawfirms/linkd" */))
const _06d01d42 = () => interopDefault(import('../pages/landings/lawfirms/tw.vue' /* webpackChunkName: "pages/landings/lawfirms/tw" */))
const _dcb6b850 = () => interopDefault(import('../pages/landings/lawyers/go.vue' /* webpackChunkName: "pages/landings/lawyers/go" */))
const _422885ea = () => interopDefault(import('../pages/landings/lawyers/linkd.vue' /* webpackChunkName: "pages/landings/lawyers/linkd" */))
const _af77401a = () => interopDefault(import('../pages/landings/lawyers/tw.vue' /* webpackChunkName: "pages/landings/lawyers/tw" */))
const _013ea32f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6b2c2bed = () => interopDefault(import('../pages/Team/Members/Join/_token/index.vue' /* webpackChunkName: "pages/Team/Members/Join/_token/index" */))
const _c448bdb4 = () => interopDefault(import('../pages/landings/reports/_report/index.vue' /* webpackChunkName: "pages/landings/reports/_report/index" */))
const _1cef339a = () => interopDefault(import('../pages/Action/_token/index.vue' /* webpackChunkName: "pages/Action/_token/index" */))
const _b0188b1c = () => interopDefault(import('../pages/Category/_hash/index.vue' /* webpackChunkName: "pages/Category/_hash/index" */))
const _00b1c753 = () => interopDefault(import('../pages/Contract-Template/_hash/index.vue' /* webpackChunkName: "pages/Contract-Template/_hash/index" */))
const _2d7932f4 = () => interopDefault(import('../pages/Contract/_hash/index.vue' /* webpackChunkName: "pages/Contract/_hash/index" */))
const _46106720 = () => interopDefault(import('../pages/File/_hash/index.vue' /* webpackChunkName: "pages/File/_hash/index" */))
const _5ca1c440 = () => interopDefault(import('../pages/Folders/_hash.vue' /* webpackChunkName: "pages/Folders/_hash" */))
const _44c71daa = () => interopDefault(import('../pages/HelpCenter/_hash/index.vue' /* webpackChunkName: "pages/HelpCenter/_hash/index" */))
const _5494a608 = () => interopDefault(import('../pages/Embed/_token/Expired/index.vue' /* webpackChunkName: "pages/Embed/_token/Expired/index" */))
const _1f41d5ff = () => interopDefault(import('../pages/Embed/_token/Notifications/index.vue' /* webpackChunkName: "pages/Embed/_token/Notifications/index" */))
const _290e4247 = () => interopDefault(import('../pages/Embed/_token/Search/index.vue' /* webpackChunkName: "pages/Embed/_token/Search/index" */))
const _7e96528d = () => interopDefault(import('../pages/Embed/_token/Subscriptions/index.vue' /* webpackChunkName: "pages/Embed/_token/Subscriptions/index" */))
const _acef488e = () => interopDefault(import('../pages/Embed/_token/File/_hash/index.vue' /* webpackChunkName: "pages/Embed/_token/File/_hash/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/Changelogs",
    component: _696b1816,
    name: "Changelogs"
  }, {
    path: "/ContactUs",
    component: _7f33809b,
    name: "ContactUs"
  }, {
    path: "/Content",
    component: _3189e414,
    name: "Content"
  }, {
    path: "/Contracts",
    component: _dd280c3e,
    name: "Contracts"
  }, {
    path: "/DataView",
    component: _67957dc2,
    name: "DataView"
  }, {
    path: "/Expired",
    component: _0e2d6abc,
    name: "Expired"
  }, {
    path: "/ExtendTrial",
    component: _646b055c,
    name: "ExtendTrial"
  }, {
    path: "/ForgotPassword",
    component: _149a8b71,
    name: "ForgotPassword"
  }, {
    path: "/GettingStarted",
    component: _226501b0,
    name: "GettingStarted"
  }, {
    path: "/HelpCenter",
    component: _0b8c74cc,
    name: "HelpCenter"
  }, {
    path: "/HighlightsAndNotes",
    component: _393a67c8,
    name: "HighlightsAndNotes"
  }, {
    path: "/Login",
    component: _6c258fae,
    name: "Login"
  }, {
    path: "/LoginOld",
    component: _6cad86d1,
    name: "LoginOld"
  }, {
    path: "/NotFound",
    component: _382fe67c,
    name: "NotFound"
  }, {
    path: "/Notifications",
    component: _0ea7bf25,
    name: "Notifications"
  }, {
    path: "/Packages",
    component: _625d2a40,
    name: "Packages"
  }, {
    path: "/PrivacyPolicy",
    component: _a1e85fd2,
    name: "PrivacyPolicy"
  }, {
    path: "/Referral",
    component: _4d3778f0,
    name: "Referral"
  }, {
    path: "/Register",
    component: _f930a20e,
    name: "Register"
  }, {
    path: "/Search",
    component: _f56eb304,
    name: "Search"
  }, {
    path: "/Settings",
    component: _1de7f314,
    name: "Settings"
  }, {
    path: "/Stream",
    component: _2c48b613,
    name: "Stream"
  }, {
    path: "/Subscriptions",
    component: _14041276,
    name: "Subscriptions"
  }, {
    path: "/Table",
    component: _324bf80b,
    name: "Table"
  }, {
    path: "/Tenants",
    component: _701d3e86,
    name: "Tenants"
  }, {
    path: "/UnderMaintenance",
    component: _552d446e,
    name: "UnderMaintenance"
  }, {
    path: "/UsagePolicy",
    component: _77e277a0,
    name: "UsagePolicy"
  }, {
    path: "/Workers",
    component: _3a057832,
    name: "Workers"
  }, {
    path: "/YLLKSA",
    component: _539d17da,
    name: "YLLKSA"
  }, {
    path: "/AI/Chat",
    component: _b7c4b0c8,
    name: "AI-Chat"
  }, {
    path: "/Forms/Contact",
    component: _fe9a9746,
    name: "Forms-Contact"
  }, {
    path: "/Forms/trial",
    component: _15bf8c9a,
    name: "Forms-trial"
  }, {
    path: "/landings/enterprise",
    component: _0993e874,
    name: "landings-enterprise"
  }, {
    path: "/landings/hr",
    component: _5cae3add,
    name: "landings-hr"
  }, {
    path: "/landings/lawfirms",
    component: _c8f11bb4,
    name: "landings-lawfirms"
  }, {
    path: "/landings/lawyers",
    component: _729f8b12,
    name: "landings-lawyers"
  }, {
    path: "/landings/reports",
    component: _7d79e43c,
    name: "landings-reports"
  }, {
    path: "/Mobile/Download",
    component: _5aa922f5,
    name: "Mobile-Download"
  }, {
    path: "/OAuth/Authorize",
    component: _2a8ebaf1,
    name: "OAuth-Authorize"
  }, {
    path: "/Payments/Fail",
    component: _595921c6,
    name: "Payments-Fail"
  }, {
    path: "/Payments/Success",
    component: _1748e754,
    name: "Payments-Success"
  }, {
    path: "/Register/OnBoarding",
    component: _a0030f78,
    name: "Register-OnBoarding"
  }, {
    path: "/Register/Success",
    component: _4ee985ca,
    name: "Register-Success"
  }, {
    path: "/Team/Members",
    component: _8d557dc6,
    name: "Team-Members"
  }, {
    path: "/landings/enterprise/go",
    component: _038211b6,
    name: "landings-enterprise-go"
  }, {
    path: "/landings/enterprise/linkd",
    component: _4dc63968,
    name: "landings-enterprise-linkd"
  }, {
    path: "/landings/enterprise/tw",
    component: _1a21cdd1,
    name: "landings-enterprise-tw"
  }, {
    path: "/landings/hr/go",
    component: _135c0026,
    name: "landings-hr-go"
  }, {
    path: "/landings/hr/linkd",
    component: _2c3735b5,
    name: "landings-hr-linkd"
  }, {
    path: "/landings/hr/tw",
    component: _0cf1bc08,
    name: "landings-hr-tw"
  }, {
    path: "/landings/lawfirms/go",
    component: _340f9578,
    name: "landings-lawfirms-go"
  }, {
    path: "/landings/lawfirms/linkd",
    component: _6b106cfe,
    name: "landings-lawfirms-linkd"
  }, {
    path: "/landings/lawfirms/tw",
    component: _06d01d42,
    name: "landings-lawfirms-tw"
  }, {
    path: "/landings/lawyers/go",
    component: _dcb6b850,
    name: "landings-lawyers-go"
  }, {
    path: "/landings/lawyers/linkd",
    component: _422885ea,
    name: "landings-lawyers-linkd"
  }, {
    path: "/landings/lawyers/tw",
    component: _af77401a,
    name: "landings-lawyers-tw"
  }, {
    path: "/",
    component: _013ea32f,
    name: "index"
  }, {
    path: "/Team/Members/Join/:token",
    component: _6b2c2bed,
    name: "Team-Members-Join-token"
  }, {
    path: "/landings/reports/:report",
    component: _c448bdb4,
    name: "landings-reports-report"
  }, {
    path: "/Action/:token",
    component: _1cef339a,
    name: "Action-token"
  }, {
    path: "/Category/:hash",
    component: _b0188b1c,
    name: "Category-hash"
  }, {
    path: "/Contract-Template/:hash",
    component: _00b1c753,
    name: "Contract-Template-hash"
  }, {
    path: "/Contract/:hash",
    component: _2d7932f4,
    name: "Contract-hash"
  }, {
    path: "/File/:hash",
    component: _46106720,
    name: "File-hash"
  }, {
    path: "/Folders/:hash?",
    component: _5ca1c440,
    name: "Folders-hash"
  }, {
    path: "/HelpCenter/:hash",
    component: _44c71daa,
    name: "HelpCenter-hash"
  }, {
    path: "/Embed/:token?/Expired",
    component: _5494a608,
    name: "Embed-token-Expired"
  }, {
    path: "/Embed/:token?/Notifications",
    component: _1f41d5ff,
    name: "Embed-token-Notifications"
  }, {
    path: "/Embed/:token?/Search",
    component: _290e4247,
    name: "Embed-token-Search"
  }, {
    path: "/Embed/:token?/Subscriptions",
    component: _7e96528d,
    name: "Embed-token-Subscriptions"
  }, {
    path: "/Embed/:token?/File/:hash",
    component: _acef488e,
    name: "Embed-token-File-hash"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
