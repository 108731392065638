
export default {
    data () {
        return {
            windowHeight: null
        }
    },

    computed: {
        links () {
            const links = [
                {
                    label: 'لماذا قانونية',
                    to: '/#whyQanoniah'
                },
                {
                    label: 'شركاء النجاح',
                    to: '/#success'
                },
                {
                    label: 'تواصل معنا',
                    to: '/ContactUs'
                }/* ,
                {
                    label: 'مدونة قانونية',
                    to: 'https://blog.qanoniah.com',
                    type: 'external'
                } */
            ]
            if (this.$auth.loggedIn) {
                links.push({
                    label: 'البحث',
                    to: '/Search'
                })
            } else {
                links.push({
                    label: 'الاشتراك',
                    to: '/Register'
                })
                links.push({
                    label: 'تسجيل الدخول',
                    to: '/Login'
                })
            }

            return links
        }
    },
    mounted () {
        this.windowHeight = (window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) - this.$refs.header.$el.offsetHeight - this.$refs.footer.$el.offsetHeight
    }
}
