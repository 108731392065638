(function (h, o, t, j, a, r) {
    h.hj = h.hj || function () {
        (h.hj.q = h.hj.q || []).push(arguments)
    }
    h._hjSettings = {
        hjid: 2749452,
        hjsv: 6
    }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')

export default function ({ $auth }) {
    if ($auth.loggedIn) {
        const userId = $auth.user?.id || null
        window.hj('identify', userId, {
            onboarding_testing_group: $auth.user.extra?.onboarding_testing_group ?? null
        })
    }
}
