export default function ({ $auth, route, redirect, $config }) {
    const ONBOARDING_PATH = '/Register/OnBoarding'

    if (route.path !== ONBOARDING_PATH && $auth.user && !$auth.user.onboarding_completed) {
        return redirect(ONBOARDING_PATH)
    }
    if (route.path === ONBOARDING_PATH && $auth.user && $auth.user.onboarding_completed) {
        return redirect('/')
    }
}
