export const state = () => ({
    preview: null
})

export const mutations = {
    setPreview (state, payload) {
        state.preview = payload
    }
}

export const getters = {
    getPreview: (state) => {
        return state.preview
    }
}
