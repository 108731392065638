
import Button from '@/components/UntitledUI/Button.vue'
import Logo from '@/components/common/Logo.vue'
export default {
    components: {
        Button, Logo
    },
    data () {
        return {
            windowHeight: null
        }
    },
    mounted () {
        this.windowHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) -
            this.$refs.header.offsetHeight
    }
}
